import { render } from '@testing-library/react';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { clearEdit } from '../../../../features/InfoSlice/infoSlice';
import Loader from '../../../Shared/Loader';


const UpdateModal = ({updateExpense, setUpdateExpense,fetchData }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [expenseHeads, setExpenseHeads] = useState([]);
    const [loading, setLoading] = useState(true);
    // const Loan Account = useSelector((state) => state.info.Loan Account[0]);
    const { _id, date, name, invoice, amount, purpose, status } = updateExpense;

    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/sarabela-hardware/getexpenseheads')
            .then(res => res.json())
            .then(data => {
                setExpenseHeads(data);
                setLoading(false)
            })
    }, [])

    const onSubmit = (data) => {
        const user = userAc?.email.split('@')[0];
        const approvedBy = false;
        fetch(`https://servers.thakurgaonexpress.com/sarabela-hardware/getexpense/${_id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({user,...data,approvedBy})
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                setUpdateExpense(null);
                toast('Expense Upadated Successful');
                fetchData();
            })
    }

    const noEdit = () => {
        window.location.reload(false);
    }

    if(loading){
        return <Loader/>
    }

    return (
        <div>
            <input type="checkbox" id="update-modal" class="modal-toggle" />
            <div class="modal mt-12">
                <div class="modal-box relative text-xs">
                    <label for="update-modal" onClick={() => noEdit()} class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 class="text-lg font-bold">Update-{name}</h3>
                    <form onSubmit={handleSubmit(onSubmit)} className='p-5 grid grid-cols-1 gap-4 justify-items-center'>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Select Date</span>
                            </label>
                            <input {...register("date")} type="date" defaultValue={date} placeholder="Date" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Expense Head Name</span>
                            </label>
                            <select style={{ padding: '18px' }} {...register("name")} defaultValue={name} class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                                <option disabled selected>Select Expense Head</option>
                                {
                                    expenseHeads.map(expenseHead => <option>{expenseHead.name}</option>)
                                }

                            </select>
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Invoice No</span>
                            </label>
                            <input {...register("invoice")} type="text" defaultValue={invoice} placeholder="Type Invoice No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Amount</span>
                            </label>
                            <input {...register("amount")} type="number" defaultValue={amount} placeholder="Type Amount Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Purpose</span>
                            </label>
                            <input {...register("purpose")} type="text" defaultValue={purpose} placeholder="Type Expense Purpose Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Status</span>
                            </label>
                            <select style={{ padding: '18px' }} {...register("status")} defaultValue={status} class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                                <option >Active</option>
                                <option>Inactive</option>
                            </select>
                        </div>
                        <input type='submit' value='Update Expense' className='btn bg-red-600 text-white' />
                    </form>
                </div>
            </div>
        </div >
    );
};

export default UpdateModal;