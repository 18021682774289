import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import UpdateModal from './UpdateModal';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { editCategory } from '../../../features/InfoSlice/infoSlice';
import Loader from '../../Shared/Loader';
import approveData from '../../../utils/approveData';
import ApprovalButton from '../../../utils/ApprovalButton';



const CategoryField = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [categories, setCategories] = useState([]);
    const [updateCategory, setUpdateCategory] = useState(null);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/sarabela-hardware/getcategories')
            .then(res => res.json())
            .then(data => {
                setCategories(data);
                setLoading(false);
            })
    }, [categories, setCategories, updateCategory, setUpdateCategory, dispatch])

    if (loading) {
        return <Loader />
    }

    const onSubmit = data => {
        const user = userAc?.email.split('@')[0]
        fetch('https://servers.thakurgaonexpress.com/sarabela-hardware/categories', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({user,...data}),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Category Added')
                reset();
            })

    }

    const handleDeletecategory = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://servers.thakurgaonexpress.com/sarabela-hardware/getcategories/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                    setUpdateCategory(null);
                })
        }
    };

    const handleupdateCategory = (category) => {

        // fetch(`https://servers.thakurgaonexpress.com/sarabela-hardware/getcategories/${category._id}`)
        //     .then(res => res.json())
        //     .then(data => {
        //         dispatch(editCategory(data))
        //     })
        setUpdateCategory(category);
    }

    const handleApprove=(category)=>{
        const approvedBy = userAc?.email.split('@')[0];
        fetch(`https://servers.thakurgaonexpress.com/sarabela-hardware/getcategories/${category._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({approvedBy})
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast('Brand Approved');
                // window.location.reload(false)
            })
    }

    return (
        <div className='shadow-2xl px-2 pb-5 my-5 text-xs'>
            <form onSubmit={handleSubmit(onSubmit)} className='container flex flex-col lg:flex-row md:flex-row gap-2 justify-center items-center'>
                <div class="form-control w-80 max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Category Name</span>
                    </label>
                    <input {...register("name")} type="text" placeholder="Type Category Name Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" />
                </div>
                <div class="form-control w-80 max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Status</span>
                    </label>
                    <select style={{ padding: '18px' }} {...register("status")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                        <option selected>Active</option>
                        <option>Inactive</option>
                    </select>
                </div>
                <input disabled={!userAc?.addCategory} type="submit" value='Add Category' className='btn bg-red-600 text-white lg:mt-9' />
            </form>

            {
                categories.length ? <div class="overflow-x-auto mt-8">
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>SL</th>
                                <th className='bg-red-600 text-white normal-case'>Category Name</th>
                                <th className='bg-red-600 text-white normal-case'>Status</th>
                                <th className='bg-red-600 text-white normal-case'>Added By</th>
                                <th className='bg-red-600 text-white normal-case'>Approved By</th>
                                <th className='bg-red-600 text-white normal-case'>Edit</th>
                                <th className='bg-red-600 text-white normal-case'>Delete</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                categories.map((category, index) => <tr className='hover'>
                                    <td>{index + 1}</td>
                                    <td>{category?.name}</td>
                                    <td> <button className={`btn btn-xs normal-case ${category.status === 'Active' ? 'bg-green-700' : 'bg-red-700'}`}>{category?.status}</button> </td>
                                    <td><button className='btn btn-xs btn-primary text-white normal-case'>{category?.user ? category.user : "User"}</button></td>
                                    {userAc.approvalCategory? <td>{category?.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{category?.approvedBy}</button>: <button onClick={()=>handleApprove(category)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td>:
                                   <td>{category.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{category.approvedBy}</button>:<button className='btn btn-xs btn-error text-white'>Not Approved</button>}</td>}
                                    {userAc?.editCategory && <td><button><label for="update-modal" onClick={() => handleupdateCategory(category)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                    {userAc?.deleteCategory && <td><button onClick={() => handleDeletecategory(category._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div> : ""
            }
            {
                updateCategory && <UpdateModal setUpdateCategory={setUpdateCategory} updateCategory={updateCategory}></UpdateModal>
            }
        </div>
    );
};

export default CategoryField;