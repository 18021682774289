import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import UpdateModal from './UpdateModal';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import Loader from '../../../Shared/Loader';
import { format, set } from 'date-fns';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useRef } from 'react';


const IncomeField = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [incomes, setIncomes] = useState([]);
    const [updateIncome, setUpdateIncome] = useState(null);
    const [incomeHeads, setIncomeHeads] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isExpend, setIsExpend] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [sort, setSort] = useState('Newest First');
    const [isFilter, setIsFilter] = useState(false);

    const sortRef = useRef('');
    const handleSort = () => {
        const sortValue = sortRef.current.value;
        if (sortValue === "Oldest First") {
            setSort("Oldest First")
        } else {
            setSort("Newest First")
        }
    }
    const date = new Date();
    const today = format(date, "yyyy-MM-dd");

    // useEffect(() => {
    //     fetch('https://servers.thakurgaonexpress.com/sarabela-hardware/getincomes')
    //         .then(res => res.json())
    //         .then(data => {
    //             if (sort !== "Oldest First") {
    //                 setIncomes(data)
    //             } else {
    //                 const sorted = data.sort(function (a, b) {
    //                     return (a?.date > b?.date) ? 1 : ((a?.date < b?.date) ? -1 : 0);
    //                 });
    //                 setIncomes(sorted);
    //             }
    //             setLoading(false)
    //         })
    // }, [sort])

    const fetchData = () => {
        fetch('https://servers.thakurgaonexpress.com/sarabela-hardware/getincomes')
            .then(res => res.json())
            .then(data => {
                if (sort !== "Oldest First") {
                    setIncomes(data);
                } else {
                    const sorted = data.sort(function (a, b) {
                        return (a?.date > b?.date) ? 1 : ((a?.date < b?.date) ? -1 : 0);
                    });
                    setIncomes(sorted);
                }
            });
    };

    useEffect(() => {
        fetchData(); // Fetch data when the component mounts
    }, [sort]);

    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/sarabela-hardware/getincomeheads')
            .then(res => res.json())
            .then(data => {
                const filter = data?.filter(eh => eh?.status === "Active")
                setIncomeHeads(filter);
                setLoading(false)
            })
    }, [])

    if (loading) {
        return <Loader />
    }

    // const onSubmit = data => {
    //     const currentDate = new Date(data.date);
    //     const formattedDate = format(currentDate, 'MMM-yyyy');
    //     const user = userAc?.email.split('@')[0];
    //     const newData = { ...data, month: formattedDate, user }
    //     fetch('https://servers.thakurgaonexpress.com/sarabela-hardware/addincome', {
    //         method: 'POST',
    //         headers: {
    //             'content-type': 'application/json'
    //         },
    //         body: JSON.stringify(newData),
    //     })
    //         .then(res => res.json())
    //         .then(data => {
    //             toast.success('Income Added')
    //             reset();
    //             setIsExpend(false);
    //             // setTimeout(() => { window.location.reload(); }, 3000);
    //         })

    // }

    const onSubmit = data => {
        const currentDate = new Date(data.date);
        const formattedDate = format(currentDate, 'MMM-yyyy');
        const user = userAc?.email.split('@')[0];
        const newData = { ...data, month: formattedDate, user }

        // Function to make the fetch request to add an expense
        const addIncome = () => {
            fetch('https://servers.thakurgaonexpress.com/sarabela-hardware/addincome', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(newData),
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Income Added');
                    reset();
                    fetchData();
                    setIsExpend(false);
                });
        };

        // Call the function to add the expense
        addIncome();
    };

    const handleDeleteIncome = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://servers.thakurgaonexpress.com/sarabela-hardware/getincome/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                    setUpdateIncome(null);
                    fetchData();
                })
        }
    };

    const handleFilteredData = e => {
        e.preventDefault();
        const startDate = e.target.startDate.value;
        const endDate = e.target.endDate.value;
        const name = e.target.category.value;
        const sortBy = e.target.sort.value;
        const url = `https://servers.thakurgaonexpress.com/sarabela-hardware/filteredincomes?startDate=${startDate}&endDate=${endDate}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                if (name === "All") {
                    if (sortBy !== "Oldest First") {
                        setIncomes(data);
                    } else {
                        const sorted = data.sort(function (a, b) {
                            return (a?.date > b?.date) ? 1 : ((a?.date < b?.date) ? -1 : 0);
                        });
                        setIncomes(sorted);
                    }
                } else {

                    if (sortBy !== "Oldest First") {
                        const filteredincomes = data.filter(income => income.name === name);
                        setIncomes(filteredincomes);
                    } else {
                        const filteredincomes = data.filter(income => income.name === name);
                        const sorted = filteredincomes.sort(function (a, b) {
                            return (a?.date > b?.date) ? 1 : ((a?.date < b?.date) ? -1 : 0);
                        });
                        setIncomes(sorted);
                    }
                }
                setIsFilter(true);
            })
    }

    const handleUpdateIncome = (income) => {
        setUpdateIncome(income);
    }

    const handleSearchResult = (event) => {
        const searchText = event.target.value.toLowerCase();
        const match = incomes.filter(income => income.name.toLowerCase().includes(searchText)
            || income.purpose.toLowerCase().includes(searchText) || income.amount.toLowerCase().includes(searchText)
            || income.date.toLowerCase().includes(searchText) || income.invoice.toLowerCase().includes(searchText));
        setSearchResult(match);
        setIsSearching(true);
    }

    const handleApprove = (income) => {
        const approvedBy = userAc?.email.split('@')[0];
        fetch(`https://servers.thakurgaonexpress.com/sarabela-hardware/getincome/${income._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ approvedBy })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast('Income Approved');
                fetchData();
            })
    }

    const totalAmount = !isSearching ? incomes.reduce((total, currentValue) => total + parseInt(currentValue?.amount), 0)
        : searchResult.reduce((total, currentValue) => total + parseInt(currentValue?.amount), 0);
    return (
        <div>
            <div className={`${isExpend ? 'shadow-2xl' : ''} px-12 pb-5 mt-5 text-xs`}>
                <div className='text-right'><button onClick={() => setIsExpend(!isExpend)} className='btn btn-sm btn-error text-white'>{isExpend === true ? <AiOutlineMinus className='text-lg font-bold' /> : <AiOutlinePlus className='text-lg font-bold' />}Add Income</button></div>
                <form onSubmit={handleSubmit(onSubmit)} className={`container grid grid-cols-1 lg:grid-cols-3 gap-1 justify-center items-center ${isExpend === true ? "" : "hidden"}`}>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Select Date</span>
                        </label>
                        <input {...register("date")} type="date" defaultValue={today} placeholder="Date" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Income Head Name</span>
                        </label>
                        <select style={{ padding: '18px' }} {...register("name")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                            <option disabled selected>Select Income Head</option>
                            {
                                incomeHeads.map(incomeHead => <option>{incomeHead.name}</option>)
                            }
                        </select>
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Invoice No</span>
                        </label>
                        <input {...register("invoice")} type="text" placeholder="Type Invoice No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Amount</span>
                        </label>
                        <input {...register("amount")} type="number" placeholder="Type Amount Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Purpose</span>
                        </label>
                        <input {...register("purpose")} type="text" placeholder="Type Income Purpose Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Status</span>
                        </label>
                        <select style={{ padding: '18px' }} {...register("status")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                            <option selected>Active</option>
                            <option>Inactive</option>
                        </select>
                    </div>
                    <input disabled={!userAc?.addIncome} type="submit" value='Add Income' className='btn lg:w-60 bg-red-600 text-white mt-2 lg:mt-9' />
                </form>
            </div>
            <div className='text-xs mx-2 shadow-2xl p-2 lg:p-5'>
                <div className={`${isExpend === false ? "" : "hidden"}`}>
                    <div className='flex justify-center lg:justify-end items-center'>
                        <form onSubmit={handleFilteredData} className='mt-12 flex flex-col lg:flex-row justify-center items-right'>
                            <div class="form-control w-full lg:w-40 max-w-xs lg:mr-2">
                                <label class="label">
                                    <span class="label-text">Starting Date</span>
                                </label>
                                <input name='startDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                            </div>
                            <div class="form-control w-full lg:w-40 max-w-xs lg:mr-2">
                                <label class="label">
                                    <span class="label-text">End Date</span>
                                </label>
                                <input name='endDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                            </div>
                            <div class="form-control w-full lg:w-60 max-w-xs lg:mr-2">
                                <label class="label">
                                    <span class="label-text">Income Head</span>
                                </label>
                                <select style={{ padding: '' }} {...register("category")} defaultValue='' class="rounded-lg text-black text-xs select select-bordered w-full max-w-xs" required>
                                    <option selected>All</option>
                                    {
                                        incomeHeads.map(incomeHead => <option>{incomeHead.name}</option>)
                                    }
                                </select>
                            </div>
                            <div class={`form-control w-full lg:w-40 max-w-xs lg:mr-2`}>
                                <label class="label">
                                    <span class="label-text">Short By</span>
                                </label>
                                <select style={{ padding: '' }} {...register("sort")} defaultValue='' class="rounded-lg text-black text-xs select select-bordered w-full max-w-xs" required>
                                    <option selected>Newest First</option>
                                    <option>Oldest First</option>
                                </select>
                            </div>
                            <input type='submit' value='Search' className='btn btn-md btn-error text-white mt-4 lg:mt-9 lg:ml-2' />
                        </form>
                    </div>
                    <div className='flex justify-end items-center my-8 lg:my-4'>
                        <div class="form-control w-full lg:w-60">
                            <input onChange={handleSearchResult} type="text" placeholder="Search Income" class="input text-xs mx-auto input-bordered bg-white input-error w-full max-w-xs" />
                        </div>
                    </div>
                    <div className={`h-8 ${isFilter === false ? "flex justify-between items-center" : "text-right"}`}>
                        <div className={`form-control w-40 max-w-xs lg:mr-2 ${isFilter === true ? "hidden" : ""}`}>
                            <label class="label">
                                <span class="label-text">Short By</span>
                            </label>
                            <select ref={sortRef} style={{ padding: '' }} onChange={handleSort} defaultValue='' class="rounded-lg text-black text-xs select select-bordered w-full max-w-xs" required>
                                <option selected>Newest First</option>
                                <option>Oldest First</option>
                            </select>
                        </div>
                        <div className='text-right'>
                            <ReactHtmlTableToExcel
                                id="test-table-xls-button"
                                className="btn btn-error text-white btn-xs mt-10 lg:mt-0"
                                table="table-to-xls"
                                filename="incomes-table"
                                sheet="incomes"
                                buttonText="Export to exel" />
                        </div>
                    </div>
                    {
                        incomes.length ? <div class="overflow-x-auto mt-8">
                            <table id="table-to-xls" class="table w-1/2 mx-auto">
                                <thead className='text-center'>
                                    <tr>
                                        <th className='bg-red-600 text-white normal-case'>SL</th>
                                        <th className='bg-red-600 text-white normal-case'>Date</th>
                                        <th className='bg-red-600 text-white normal-case'>Income Head Name</th>
                                        <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                                        <th className='bg-red-600 text-white normal-case'>Amount</th>
                                        <th className='bg-red-600 text-white normal-case'>Purpose</th>
                                        <th className='bg-red-600 text-white normal-case'>Status</th>
                                        <th className='bg-red-600 text-white normal-case'>Added By</th>
                                        <th className='bg-red-600 text-white normal-case'>Approved By</th>
                                        <th className='bg-red-600 text-white normal-case'>Edit</th>
                                        <th className='bg-red-600 text-white normal-case'>Delete</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    {
                                        isSearching ? searchResult.map((income, index) => <tr className='hover'>
                                            <td>{index + 1}</td>
                                            <td>{income.date}</td>
                                            <td>{income.name}</td>
                                            <td>{income.invoice}</td>
                                            <td>{income.amount}</td>
                                            <td>{income.purpose}</td>
                                            <td> <button className={`btn btn-xs normal-case ${income.status === 'Active' ? 'bg-green-700' : 'bg-red-700'}`}>{income?.status}</button> </td>
                                            <td><button className='btn btn-xs btn-primary text-white normal-case'>{income?.user ? income.user : "User"}</button></td>
                                            {userAc.approvalIncome ? <td>{income?.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{income?.approvedBy}</button> : <button onClick={() => handleApprove(income)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td> :
                                                <td>{income.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{income.approvedBy}</button> : <button className='btn btn-xs btn-error text-white'>Not Approved</button>}</td>}
                                            {userAc?.editIncome && <td><button><label for="update-modal" onClick={() => handleUpdateIncome(income)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                            {userAc?.deleteIncome && <td><button onClick={() => handleDeleteIncome(income._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                        </tr>) : incomes.map((income, index) => <tr className='hover'>
                                            <td>{index + 1}</td>
                                            <td>{income.date}</td>
                                            <td>{income.name}</td>
                                            <td>{income.invoice}</td>
                                            <td>{income.amount}</td>
                                            <td>{income.purpose}</td>
                                            <td> <button className={`btn btn-xs normal-case ${income.status === 'Active' ? 'bg-green-700' : 'bg-red-700'}`}>{income?.status}</button> </td>
                                            <td><button className='btn btn-xs btn-primary text-white normal-case'>{income?.user ? income.user : "User"}</button></td>
                                            {userAc.approvalIncome ? <td>{income?.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{income?.approvedBy}</button> : <button onClick={() => handleApprove(income)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td> :
                                                <td>{income.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{income.approvedBy}</button> : <button className='btn btn-xs btn-error text-white'>Not Approved</button>}</td>}
                                            {userAc?.editIncome && <td><button><label for="update-modal" onClick={() => handleUpdateIncome(income)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                            {userAc?.deleteIncome && <td><button onClick={() => handleDeleteIncome(income._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                        </tr>)
                                    }
                                </tbody>
                                <tfoot className='text-center'>
                                    <tr>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                        <th className='bg-red-600 text-white normal-case'>Total</th>
                                        <th className='bg-red-600 text-white normal-case'>{totalAmount}</th>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div> : ""
                    }
                </div>
            </div>
            {
                updateIncome && <UpdateModal fetchData={fetchData} setUpdateIncome={setUpdateIncome} updateIncome={updateIncome}></UpdateModal>
            }
        </div>
    );
};

export default IncomeField;