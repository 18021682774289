import logo from '../../assets/images/logo.jpeg'

const BusinessInfo = {
    name: "Sarabela Hardware",
    fullName: "M/S Sarabela Hardware",
    dealer: "(Authorized Dealer RFL,Total, Makute)",
    address: "Valuka, Mymensingh",
    mobile: "Mobile: 01715-886655",
    img: logo,
}

export default BusinessInfo;
